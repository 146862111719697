import React from "react";
import { HashLink } from "react-router-hash-link";

export default function Menu() {
  return (
    <nav className="flex justify-center md:justify-between items-center pt-8 md:py-8 px-4 max-w-7xl w-full">
      <h6 className="text-cabinet text-secondary font-black md:text-base text-xl">
        Quentin Robert
      </h6>
      <ul className="hidden md:flex gap-4">
        <HashLink to="#portfolio">Portfolio</HashLink>
        <HashLink to="#process">Process</HashLink>
        {/* <HashLink to="#about">Témoignages</HashLink> */}
        <HashLink to="#contact">Contact</HashLink>
      </ul>
    </nav>
  );
}
