import React, { useState, useEffect } from "react";

export default function Process() {
  const processSteps = [
    {
      key: "1",
      title: "Cadrage de votre projet",
      description:
        "Nous allons nous entretenir avec vous dans un appel ou par message pour cerner vos besoins et déterminer la suite du processus de création de votre site.",
    },
    {
      key: "2",
      title: "Design de votre maquette",
      description:
        "Durant cette partie nous créons le design de votre site et recueillons vos retours pour que vous ayez un site efficace qui vous plaît.",
    },
    {
      key: "3",
      title: "Développement Webflow",
      description:
        "Nous allons développer votre site sur Webflow, un outil de création de site web qui vous permettra de modifier votre site facilement par la suite.",
    },
    {
      key: "4",
      title: "Optimisation pour les moteurs de recherche",
      description:
        "Nous allons optimiser votre site pour les moteurs de recherche pour que vous soyez visible sur Google et autres moteurs de recherche.",
    },
  ];

  const [isCentered, setIsCentered] = useState(processSteps.map(() => false));

  useEffect(() => {
    const checkCenter = () => {
      const newIsCentered = processSteps.map((step, index) => {
        const element = document.querySelector(`#step-${step.key}`);
        const rect = element.getBoundingClientRect();
        const centerY = rect.top + rect.height / 3;
        const viewportCenterY = window.innerHeight / 1.65;
        return centerY < viewportCenterY;
      });
      setIsCentered(newIsCentered);
    };

    window.addEventListener("scroll", checkCenter);
    checkCenter(); // Initial check on mount

    return () => window.removeEventListener("scroll", checkCenter);
  }, []);

  return (
    <div
      className="flex flex-col justify-center items-center gap-8 max-w-7xl"
      id="process"
    >
      <div className="flex flex-col justify-center items-center">
        <h6 className="text-secondary font-bold font-cabinet text-base uppercase">
          Processus
        </h6>
        <h1 className="text-secondary font-black font-cabinet text-3xl">
          Un déroulé simple
        </h1>
      </div>

      <div className="">
        {processSteps.map((step, index) => (
          <div key={step.key} className="flex gap-8">
            <div className="flex flex-col gap-3 justify-between items-center">
              {/* <div
                id={`step-${step.key}`}
                className={`h-16 w-1 ${isCentered[index] ? "bg-secondary" : "bg-main"}`}
              ></div>
              <div
                className={`h-4 w-4 min-h-[16px] min-w-[16px] ${isCentered[index] ? "bg-secondary" : "bg-main"} rounded-full`}
              ></div>
              <div
                className={`w-1 h-full ${isCentered[index] ? "bg-secondary" : "bg-main"}`}
              ></div> */}{" "}
              <div
                id={`step-${step.key}`}
                className={`h-16 w-1 transition-all duration-500 ease-in-out ${isCentered[index] ? "bg-secondary" : "bg-main"}`}
              ></div>
              <div
                className={`h-4 w-4 min-h-[16px] min-w-[16px] rounded-full transition-all duration-500 ease-in-out ${isCentered[index] ? "bg-secondary" : "bg-main"}`}
              ></div>
              <div
                className={`w-1 h-full transition-all duration-500 ease-in-out ${isCentered[index] ? "bg-secondary" : "bg-main"}`}
              ></div>
            </div>
            <div className="flex gap-8 py-6">
              {/* <img src="" /> */}
              <div className="flex flex-col gap-1 max-w-md">
                <div className="font-bold text-lg mb-1">{step.title}</div>
                <div className="text-md">{step.description}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
