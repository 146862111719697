import React from "react";
import { Link } from "react-router-dom";

export default function ProjectCard({ illustration, title, link }) {
  return (
    <div className="flex flex-col gap-8 p-6 bg-white rounded-[32px]">
      <img
        src={illustration}
        alt={title}
        className="rounded-[24px] h-[352px] w-[616px] object-cover object-center"
      />
      <div className="flex justify-between items-center">
        <h6 className="font-bold text-secondary text-3xl">{title}</h6>
        <Link
          to={link}
          target="_blank"
          className="border border-secondary text-secondary py-3 px-4 rounded-[64px]"
        >
          Découvrir →
        </Link>
      </div>
    </div>
  );
}
