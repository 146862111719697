import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Tagline() {
  return (
    <>
      <section className="flex flex-col justify-center text-center gap-4 max-w-7xl">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-black">
          Des sites web sur-mesure pour <br />
          présenter votre activité
        </h1>
        <p className="font-medium">
          Nous créons votre site vitrine sur-mesure pour présenter <br />
          votre produit ou votre service
        </p>
      </section>
      <div className="flex gap-4 justify-center">
        <HashLink
          to="/#offers"
          className="border border-secondary text-secondary py-3 px-4 rounded-[64px]"
        >
          Voir les prix
        </HashLink>
        <Link
          to="https://cal.com/quentinrobert/30min"
          target="_blank"
          className="border border-secondary text-main py-3 px-4 bg-secondary rounded-[64px]"
        >
          Réserver un appel →
        </Link>
      </div>
    </>
  );
}
