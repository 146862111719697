import React from "react";
import check from "../../assets/Images/check.svg";

export default function Offer({ image, title, description, price, elements }) {
  return (
    <div className="flex flex-col bg-secondary rounded-[2rem] overflow-hidden w-full">
      <img
        src={image}
        alt="illustration d'un site créé"
        className="h-[380px] object-cover w-full"
      />
      <div className="flex flex-col justify-between p-8 text-white gap-16 grow">
        <div className="flex flex-col gap-8">
          <h2 className="font-bold text-3xl">{title}</h2>
          <div className="flex flex-col gap-4">
            <p>{description}</p>
            <div className="flex flex-col gap-3">
              {elements.map((element) => (
                <div className="flex gap-3" key={element}>
                  <img src={check} alt="" className="h-4" />
                  <p>{element}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="text-2xl font-bold text-right">
          <h6>{price}</h6>
        </div>
      </div>
    </div>
  );
}
