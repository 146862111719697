import React from "react";
import Offer from "../../components/Offer/Offer";
import VeigaMotion from "../../assets/Images/VeigaMotion.png";
import Lidap from "../../assets/Images/Lidap.png";
import { Link } from "react-router-dom";

export default function Offers() {
  return (
    <div
      className="flex flex-col justify-center items-center gap-8 p-16 bg-tertiary rounded-[4rem] max-w-7xl"
      id="offers"
    >
      <div className="flex flex-col justify-center items-center">
        <h6 className="text-secondary font-bold font-cabinet text-base uppercase">
          Tarifs
        </h6>
        <h1 className="text-secondary font-black font-cabinet text-3xl">
          Mes Offres
        </h1>
      </div>
      <div className="flex md:flex-row flex-col gap-8">
        <Offer
          image={VeigaMotion}
          title="Offre standard"
          description="Une page claire et unique pour vous présenter de la meilleure manière possible. "
          price="750€"
          elements={[
            "Une page",
            "Autant de sections que souhaitées",
            "Maquette sous 7 jours",
            "Développement sous 7 jours",
            "Modifications illimitées jusqu'à satisfaction complète",
            "Responsive design (adaptation aux appareils de toutes tailles)",
            "Mise en place du SEO de votre site pour référencer votre site",
          ]}
        />
        <Offer
          image={Lidap}
          title="Offre complète"
          description="Un site complet et unique pour vous présenter de la meilleure manière possible. "
          price="1450€"
          elements={[
            "Site jusqu’à 5 pages",
            "Autant de sections que souhaitées",
            "Maquette sous 12 jours",
            "Développement sous 12 jours",
            "Modifications illimitées",
            "Responsive design (adaptation aux appareils de toutes tailles)",
            "Mise en place du SEO de votre site pour référencer votre site ",
            "Implémentation CMS (Blog)",
          ]}
        />
      </div>
      <Link
        to="https://cal.com/quentinrobert/30min"
        target="_blank"
        className="border border-secondary text-main py-3 px-4 bg-secondary rounded-[64px]"
      >
        Démarrer votre site →
      </Link>
    </div>
  );
}
