import React from "react";
import { Link } from "react-router-dom";

export default function CTA() {
  return (
    <div
      className={`p-16 bg-secondary rounded-[32px] flex flex-col gap-8 items-start bg-textureCTA max-w-7xl mx-4`}
      id="contact"
    >
      <div className="flex flex-col gap-4">
        <h3 className="text-main font-bold text-3xl">
          Mettez votre offre en avant
        </h3>
        <p className="text-main font-normal max-w-[614px]">
          Présentez votre offre avec un site moderne et distinguez vous de la
          concurrence. Nous mettons à votre disposition une équipe sur-mesure
          pour un résultat unique.
        </p>
      </div>
      <Link
        to="https://cal.com/quentinrobert/30min"
        target="_blank"
        className="bg-yellow text-secondary rounded-full py-2 px-4 font-semibold text-base"
      >
        Prendre un RDV →
      </Link>
    </div>
  );
}
