import React from "react";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import VeigaMotion from "../../assets/Images/VeigaMotion.png";
import Lidap from "../../assets/Images/Lidap.png";
import Lovis from "../../assets/Images/Lovis.png";
import pinAds from "../../assets/Images/pinAds.png";

export default function Portfolio() {
  return (
    <section
      className="flex flex-col justify-center items-center gap-8 max-w-7xl"
      id="portfolio"
    >
      <div className="flex flex-col justify-center items-center">
        <h6 className="text-secondary font-bold font-cabinet text-base uppercase">
          Portfolio
        </h6>
        <h1 className="text-secondary font-black font-cabinet text-3xl">
          Mes réalisations
        </h1>
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex lg:flex-row flex-col gap-8">
          <ProjectCard
            illustration={VeigaMotion}
            title="Veiga Motion"
            link="https://veiga-motion.webflow.io/"
          />
          <ProjectCard
            illustration={Lidap}
            title="Lidap"
            link="https://www.lidap.io/"
          />
        </div>
        <div className="flex lg:flex-row flex-col gap-8">
          <ProjectCard
            illustration={Lovis}
            title="Lovis"
            link="https://www.lovis.care/"
          />
          <ProjectCard
            illustration={pinAds}
            title="Pin-Ads"
            link="https://www.pin-ads.com/grand-compte"
          />
        </div>
      </div>
    </section>
  );
}
